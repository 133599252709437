import { Navigate, Route, Routes } from 'react-router-dom'
import './App.css'
import { HomePortalAccess } from './components/pages/HomePortalAccess/HomePortalAccess'
export const AppSSO = () => {
  return (
    <>
      <Routes>
        <Route exact path="/acceso-clientes" element={<HomePortalAccess />} />
        <Route path="*" element={<Navigate to="/acceso-clientes" replace />} />
      </Routes>
    </>
  )
}
